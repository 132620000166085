import React, { useState, useEffect } from 'react';
import Error404 from "../views/Error.js";
import metadataFunctions from '../SupabaseChecking.js';
import AdminRoles from '../enum/roles.js';

const ProtectedRouteAdminDoc = ({ element }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [Admin, setAdmin] = useState('');

  useEffect(() => {
    const checkAuthorization = async () => {
      const GetAdmin = await metadataFunctions.GetAdmin();
      setAdmin(GetAdmin);
      setIsLoading(false);
    };

    checkAuthorization();
  }, []);

  if (!isLoading) {
    if (Admin !== AdminRoles.FULL_ADMIN && Admin !== AdminRoles.DOC_ADMIN) {
      return <Error404 />;
    }
    else {
      return element;
    }
  }
};

export default ProtectedRouteAdminDoc;

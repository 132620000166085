import { lazy } from "react";
import { Navigate } from "react-router-dom";
import ProtectedRoute from "../components/ProtectedRoute.js"; // Importa el ProtectedRoute
import ProtectedRouteAdmin from "../components/ProtectedRouteAdmin.js";
import ProtectedRouteAdminDoc from "../components/ProtectedRouteAdminDoc.js";
import ProtectedRouteSupvRider from "../components/ProtectedRouteSupvRider.js";
import metadataFunctions from '../SupabaseChecking.js'


/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout.js"));
const FullLayoutAdmin = lazy(() => import("../layouts/FullLayoutAdmin.js"));

/***** Pages ****/

const Error404 = lazy(() => import("../views/Error.js"));
const Starter = lazy(() => import("../views/Starter.js"));
const Setup = lazy(() => import("../views/ui/Setup.jsx"));
const Account = lazy(() => import("../views/ui/Account.jsx"));
const Login = lazy(() => import("../views/ui/Login.js"));
const Register = lazy(() => import("../views/ui/Register.js"));
const ForgetPassword = lazy(() => import("../views/ui/ForgetPassword.js"));
const Dashboard = lazy(() => import("../views/ui/Dashboard.jsx"));
const Organization = lazy(() => import("../views/ui/Organization.js"));
const Performance = lazy(() => import("../views/ui/Performance.js"));
const Insurance = lazy(() => import("../views/ui/Insurance.js"));

const Drivers = lazy(() => import("../views/ui/Drivers.js"));
const DriverDetails = lazy(() => import("../views/ui/DriverDetails.jsx"));
const Vehicles = lazy(() => import("../views/ui/Vehicles.js"));
const VehicleDetails = lazy(() => import("../views/ui/VehicleDetails.jsx"));
const Authorizations = lazy(() => import("../views/ui/Authorizations.js"));
const Issues = lazy(() => import("../views/ui/Issues.js"));
const About = lazy(() => import("../views/About.js"));
const Alerts = lazy(() => import("../views/ui/Alerts"));
const Badges = lazy(() => import("../views/ui/Badges"));
const Buttons = lazy(() => import("../views/ui/Buttons"));
const Cards = lazy(() => import("../views/ui/Cards"));
const Grid = lazy(() => import("../views/ui/Grid"));
const Tables = lazy(() => import("../views/ui/Tables"));
const Forms = lazy(() => import("../views/ui/Forms"));
const Breadcrumbs = lazy(() => import("../views/ui/Breadcrumbs"));
const Invitation = lazy(() => import("../views/ui/Invitation.js"));

/*****Admin Routes******/
const Admin = lazy(() => import("../views/ui/Admin.jsx"));
const AdminDashboard = lazy(() => import("../views/ui/AdminDashboard.jsx"));
const UserControl = lazy(() => import("../views/ui/Admin/UserControl.js"));
const Settings = lazy(() => import("../views/ui/Admin/Settings.js"));
const UserOrgStatus = lazy(() => import("../views/ui/Admin/UserOrgStatus.js"));
const UserDocStatus = lazy(() => import("../views/ui/Admin/UserDocStatus.js"));
const UserAdminStatus = lazy(() => import("../views/ui/Admin/UserAdminStatus.js"));
const InsuranceDocuments = lazy(() => import("../components/admin/InsuranceTables.js"));

/*****Admin Doc Routes******/
//const InsuranceDocsTables = lazy(() => import("../components/adminDoc/InsuranceDocsTables.js"));
const DriverDocumentsApproval = lazy(() => import("../components/adminDoc/DriverDocumentsApproval.jsx"));
const VehicleDocumentsApproval = lazy(() => import("../components/adminDoc/VehicleDocumentsApproval.jsx"));

/*****Supervisor Rider Routes******/
const Rides = lazy(() => import("../views/ui/Rides.js"));
const AddRides = lazy(() => import("../views/ui/AddRides.js"));
/*****Routes******/

// const Error = () => {
//   // return <h2>Error 404</h2>;
//   <Navigate to="/admin/dashboard" /> 
// };


const ThemeRoutes = [
  //no login
  {
    path: "/login", exact: true, element: <Login />,
  },
  {
    path: "/register", exact: true, element: <Register />,
  },
  {
    path: "/forgetpassword", exact: true, element: <ForgetPassword />,
  },
  {
    path: "/invitation", exact: true, element: <Invitation />,
  },
  {
    path: "*", exact: true, element: <Error404 />,
  },


  //is Admin
  {
    path: "/admin",
    element: <ProtectedRouteAdmin element={<FullLayoutAdmin />} />,
    
    children: [
      { path: "/admin", element: <Navigate to="/admin/dashboard" /> },
      //{ path: "/admin", exact: true, element: <ProtectedRouteAdmin element={<AdminPanel />} />, },
      { path: "/admin/dashboard", exact: true, element: <ProtectedRouteAdmin element={<AdminDashboard />} />, },
      { path: "/admin/documents", exact: true, element: <ProtectedRouteAdmin element={<InsuranceDocuments />} />, },
      { path: "/admin/usercontrol", exact: true, element: <ProtectedRouteAdmin element={<UserControl />} />, },
      { path: "/admin/settings", exact: true, element: <ProtectedRouteAdmin element={<Settings />} />, }, 
      //{ path: "/admin/rides", exact: true, element: <ProtectedRouteAdmin element={<Rides />} />, }, 
      // User Type Listing
      { path: "/admin/userorg", exact: true, element: <ProtectedRouteAdmin element={<UserOrgStatus />} />, },
      { path: "/admin/userdoc", exact: true, element: <ProtectedRouteAdmin element={<UserDocStatus />} />, },
      { path: "/admin/useradmin", exact: true, element: <ProtectedRouteAdmin element={<UserAdminStatus />} />, },
      { path: "/admin/issues", exact: true, element: <ProtectedRouteAdmin element={<Issues />} />, },

      { path: "/admin/driver_documents", exact: true, element: <ProtectedRouteAdmin element={<DriverDocumentsApproval />} />, },
      { path: "/admin/vehicle_documents", exact: true, element: <ProtectedRouteAdmin element={<VehicleDocumentsApproval />} />, },
    ],
  },

  //is AdminDoc
  {
    path: "/admindoc",
    element: <ProtectedRouteAdminDoc element={<FullLayoutAdmin />} />,
    
    children: [
      { path: "/admindoc", element: <Navigate to="/admindoc/dashboard" /> },
      //{ path: "/admin2", exact: true, element: <ProtectedRouteAdmin element={<AdminPanel />} />, },
      { path: "/admindoc/dashboard", exact: true, element: <AdminDashboard />},
      //{ path: "/admindoc/insurance_documents", exact: true, element: <ProtectedRouteAdminDoc element={<InsuranceDocsTables />} />, },
      { path: "/admindoc/driver_documents", exact: true, element: <ProtectedRouteAdminDoc element={<DriverDocumentsApproval />} />, },
      { path: "/admindoc/vehicle_documents", exact: true, element: <ProtectedRouteAdminDoc element={<VehicleDocumentsApproval />} />, },
     // { path: "/admindoc/settings", exact: true, element: <ProtectedRouteAdmin element={<Settings />} />, }, 
      //{ path: "/admin2/rides", exact: true, element: <ProtectedRouteAdmin element={<Rides />} />, }, 
      // User Type Listing
     // { path: "/admindoc/userorg", exact: true, element: <ProtectedRouteAdmin element={<UserOrgStatus />} />, },
     // { path: "/admindoc/userdoc", exact: true, element: <ProtectedRouteAdmin element={<UserDocStatus />} />, },
      //{ path: "/admindoc/useradmin", exact: true, element: <ProtectedRouteAdmin element={<UserAdminStatus />} />, },
    ],
  },

  //is Supervisor
  {
    path: "/supervisor_rider",
    element: <ProtectedRouteSupvRider element={<FullLayoutAdmin />} />,
    
    children: [
      { path: "/supervisor_rider", element: <Navigate to="/supervisor_rider/rides" /> },
      { path: "/supervisor_rider/rides", exact: true, element: <ProtectedRouteSupvRider element={<Rides />} />, },
      { path: "/supervisor_rider/add-rides", exact: true, element: <ProtectedRouteSupvRider element={<AddRides />} />, },
    ],
  },

  {
    path: "/",
    element: <ProtectedRoute element={<FullLayout />} conditions={[() => Promise.resolve(true)]} />, // Esta ruta siempre permite el acceso
    children: [
      { path: "/", element: <Navigate to="/dashboard" /> },
      { path: "/setup", exact: true, element: <ProtectedRoute element={<Setup />} conditions={[() => Promise.resolve(true)]} /> },
      { path: "/account", exact: true, element: <ProtectedRoute element={<Account />} conditions={[() => Promise.resolve(true)]} /> },
      { path: "/dashboard", exact: true, element: <ProtectedRoute element={<Dashboard />} conditions={[metadataFunctions.CheckAccessInsuranceRoute]} /> },
      { path: "/organization", exact: true, element: <ProtectedRoute element={<Organization />} conditions={[() => Promise.resolve(true)]} /> },
      { path: "/performance", exact: true, element: <ProtectedRoute element={<Performance />} conditions={[metadataFunctions.CheckInsuranceCondition]} /> },
      { path: "/insurance", exact: true, element: <ProtectedRoute element={<Insurance />} conditions={[metadataFunctions.CheckAccessInsuranceRoute]} /> },
      { path: "/rides", exact: true, element: <ProtectedRoute element={<Rides />} conditions={[metadataFunctions.CheckAccessToRides(localStorage.getItem('companyID'))]} /> },
      { path: "/drivers", exact: true, element: <ProtectedRoute element={<Drivers />} conditions={[metadataFunctions.CheckInsuranceCondition]} /> },
      { path: "/drivers/:id", exact: true, element: <ProtectedRoute element={<DriverDetails />} conditions={[metadataFunctions.CheckInsuranceCondition]} /> },
      { path: "/vehicles", exact: true, element: <ProtectedRoute element={<Vehicles />} conditions={[metadataFunctions.CheckInsuranceCondition]} /> },
      { path: "/vehicles/:id", exact: true, element: <ProtectedRoute element={<VehicleDetails />} conditions={[metadataFunctions.CheckInsuranceCondition]} /> },
      { path: "/authorizations", exact: true, element: <ProtectedRoute element={<Authorizations />} conditions={[metadataFunctions.CheckInsuranceCondition]} /> },
      { path: "/issues", exact: true, element: <ProtectedRoute element={<Issues />} conditions={[metadataFunctions.CheckInsuranceCondition]} /> },
      { path: "/starter", exact: true, element: <ProtectedRoute element={<Starter />} conditions={[() => Promise.resolve(true)]} /> },
    ],
  }
];

export default ThemeRoutes;


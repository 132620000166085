import React, { useState, useEffect } from 'react';
import Error404 from "../views/Error.js";
import metadataFunctions from '../SupabaseChecking.js';
import AdminRoles from '../enum/roles.js';

const ProtectedRouteSupvRider = ({ element }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [Admin, setAdmin] = useState('');
  const [IsAdminDoc, setIsAdminDoc] = useState(false);

  useEffect(() => {
    const checkAuthorization = async () => {
      //const isAdmin = await metadataFunctions.isAdmin()/*localStorage.getItem("isAdmin") === "true"*/;
      const GetAdmin = await metadataFunctions.GetAdmin();
      setAdmin(GetAdmin);
      //console.log("isAdmin", isAdmin);
      //console.log("isAdminDoc", isAdminDoc);

      //setIsAdmin(isAdmin);
      //setIsAdminDoc(isAdminDoc);
      setIsLoading(false);
    };

    checkAuthorization();
  }, []);

  if (!isLoading) {
    if (Admin == AdminRoles.DOC_ADMIN || Admin == AdminRoles.USER) {
      return <Error404 />;
    }
    else {
      return element;
    }
  }
};

export default ProtectedRouteSupvRider;


import { supabase } from './SupabaseClient' // Asegúrate de importar correctamente tu cliente de Supabase
import AdminRoles from './enum/roles';
import documentDriverTypes from './types/documentDriverTypes';
import documentVehicleTypes from './types/documentVehicleTypes';
import { encrypt } from './cryptoUtils';
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_TRANSIMED_URL
/**
 * Verifica IsCompletedSetup en user_metadata.
 * @returns {boolean} - Retorna true si todos los campos existen y son válidos, de lo contrario, false.
 */
const IsCompletedSetup = async () => {
    const { data, error } = await supabase.auth.getSession();


    if (error) {
        console.error("Error fetching session:", error);
        return false;
    }

    const userMetadata = data?.session?.user?.user_metadata;

    //console.log(userMetadata);
    if (!userMetadata) {
        return false;
    }

    const { street, city, state, zip, general_liability, automobile_liability, workrs_compensation, drivers_and_vehicles } = userMetadata;

    // Verificación estricta: asegurarse de que los campos existen y no están vacíos
    const fieldsAreValid = [street, city, state, zip, general_liability, automobile_liability, workrs_compensation, drivers_and_vehicles].every(field => typeof field === 'string' && field.trim().length > 0);

    //console.log(fieldsAreValid);
    return fieldsAreValid;
};

/**
 * Verifica la existencia de los campos street, city, state, Zip, automobile_liability, workrs_compensation y drivers_and_vehiclesen user_metadata.
 * @returns {boolean} - Retorna true si todos los campos existen y son válidos, de lo contrario, false.
 */
const checkCompanyAddress = async (companyID) => {
    const { data: companies, error } = await supabase
        .from('companies')
        .select('street,city,state,zip')
        .eq('id', companyID)
        .single();


    if (error) {
        console.error("Error fetching session:", error);
        return false;
    }

    //console.log(companies);
    const { street, city, state, zip } = companies;

    // Verificación estricta: asegurarse de que los campos existen y no están vacíos
    const fieldsAreValid = [street, city, state, zip].every(field => typeof field === 'string' && field.trim().length > 0);

    //console.log(fieldsAreValid);
    return fieldsAreValid;
};

/**
 * Verifica la existencia de los campos general_liability, automobile_liability, workrs_compensation en user_metadata.
 * @returns {boolean} - Retorna true si todos los campos existen y son válidos, de lo contrario, false.
 */
const checkUserMetadataDoc = async (companyID) => {
    //const { data, error } = await supabase.auth.getSession();

    const { data: docs, error } = await supabase
        .from('insurance_docs')
        .select('file_name')
        .eq('company_id', companyID);

    if (error) {
        console.error("Error fetching session:", error);
        return false;
    }


    //("length");
    //console.log(docs.length);
    return docs.length >= 3;
};

/**
 * Verifica la existencia de los campos en user_metadata.
 * @returns {Object} - Retorna UserMetadata si todos.
 */
const UserMetadata = async () => {
    const { data, error } = await supabase.auth.getSession();

    if (error) {
        console.error("Error fetching session:", error);
        return null;
    }

    const userMetadata = data?.session?.user?.user_metadata;

    if (!userMetadata) {
        return null;
    }

    //const { street, city, state, zip } = userMetadata;

    // Verificación estricta: asegurarse de que los campos existen y no están vacío
    return userMetadata;
};

/**
 * Verifica si el email del usuario está confirmado.
 * @returns {boolean} - Retorna true si el email está confirmado, de lo contrario, false.
 */
const isEmailConfirmed = async () => {
    const { data, error } = await supabase.auth.getSession();

    if (error) {
        console.error("Error fetching session:", error);
        return false;
    }

    const userMetadata = data?.session?.user?.user_metadata;
    return userMetadata?.email_verified === true;
};

/**
 * Verifica si el teléfono del usuario está confirmado.
 * @returns {boolean} - Retorna true si el teléfono está confirmado, de lo contrario, false.
 */
const isPhoneConfirmed = async () => {
    const { data, error } = await supabase.auth.getSession();

    if (error) {
        console.error("Error fetching session:", error);
        return false;
    }

    const userMetadata = data?.session?.user?.user_metadata;
    return userMetadata?.phone_verified === true;
};

/**
 * Verifica si el user es Admin.
 * @returns {boolean} - Retorna true si es admin, de lo contrario, false.
 */
const isAdmin = async () => {
    try {
        let role = "";
        const { data, error } = await supabase.auth.getSession();


        if (error) {
            console.error("Error fetching session:", error);
            return false;
        }
        else {

            role = data.session.user.role;
            //console.log(role);

            switch (role) {
                case AdminRoles.FULL_ADMIN:
                    //localStorage.setItem('isAdmin', "true");
                    return true;
                default:
                    return false;
            }
        }
    } catch {
        return false;
    }


};

/**
 * Verifica si el user es Admin.
 * @returns {boolean} - Retorna true si es admin, de lo contrario, false.
 */
const isAdminDoc = async () => {
    try {
        let role = "";
        const { data, error } = await supabase.auth.getSession();


        if (error) {
            console.error("Error fetching session:", error);
            return false;
        }
        else {

            role = data.session.user.role;
            //console.log(role);

            switch (role) {
                case AdminRoles.DOC_ADMIN:
                    //localStorage.setItem('isAdminDoc', "true");
                    return true;
                default:
                    return false;
            }
        }
    }
    catch {
        return false;
    }


};

/**
 * Verifica si el user es Admin.
 * @returns {boolean} - Retorna true si es admin, de lo contrario, false.
 */
const GetAdmin = async () => {

    try {
        let userId = "";
        const { data, error } = await supabase.auth.getSession();


        if (error) {
            console.error("Error fetching session:", error);
            return "user";
        }
        else {
            const role = data.session.user.role;
            switch (role) {
                case AdminRoles.FULL_ADMIN:
                    return AdminRoles.FULL_ADMIN;
                case AdminRoles.DOC_ADMIN:
                    return AdminRoles.DOC_ADMIN;
                case AdminRoles.ORGANIZATION_ADMIN:
                    return AdminRoles.ORGANIZATION_ADMIN;
                case AdminRoles.ORGANIZATION_USER:
                    return AdminRoles.ORGANIZATION_USER;
                case AdminRoles.RIDER_SUPERVISOR:
                    return AdminRoles.RIDER_SUPERVISOR;
                default:
                    return AdminRoles.USER;
            }

        }
    } catch (error) {
        console.error('Error fetching session:', error);
    }
};

/**
 * Verifica si existe el archivo.
 * @returns {boolean} - Retorna true si existe el archivo, de lo contrario, false.
 */
const isFileExist = async (e) => {

    try {

        const { data, error } = await supabase
            .storage
            .from('Insurance Documents')
            .list('', {
                limit: 100,
                offset: 0,
                sortBy: { column: 'name', order: 'asc' },
            });
        //console.log(data);
        return data;

    }
    catch (error) {

    }
};

/**
 * Crea record de los archivos que se suben.
 * @returns {boolean} - Retorna el objeto que se subio a storage.
 */
const InsertDocumentsDoc = async (key, name, expiry_date = null, companyid, companyname, updatedate, status = 'pending') => {

    try {
        const { error: insertError } = await supabase
            .from('insurance_docs')
            .upsert([
                {
                    key: key,
                    file_name: name,
                    expires: expiry_date,
                    status: "pending",
                    company_id: companyid,
                    company_name: companyname,
                    uploaded: updatedate,
                    status: status
                },
            ]).select();

        if (insertError) {
            console.error('Error inserting document info:', insertError);
            return true;
        }
        else {
            return false;
        }

    }
    catch (error) {
        console.error('Error inserting document info:', error);
        return false;
    }
};

const IsAllDocumentsApproved = async (companyid) => {
    try {
        // Obtener todos los documentos para la empresa
        const { data: docs, error } = await supabase
            .from('insurance_docs')
            .select('*')
            .eq('company_id', companyid);

        if (error) {
            console.error('Error fetching documents:', error);
            return false;
        }

        // Contar cuántos documentos están en estado "approved"
        const approvedCount = docs.filter(doc => doc.status === 'approved').length;

        //console.log(docs.filter(doc => doc.status === 'approved').length)
        //console.log(approvedCount >= 3)
        // Verificar si hay 3 o más documentos aprobados
        return approvedCount >= 3;

    } catch (error) {
        console.error('IsAllDocumentsApproved: ' + error);
        return false;
    }
};


const GetAllDocumentsByCompany = async (companyid) => {
    try {
        const { data: docs, error } = await supabase
            .from('insurance_docs')
            .select('*')
            .eq('company_id', companyid);

        if (!error) {

            const groupedByCompany = docs.reduce((acc, item) => {
                if (!acc[item.company_id]) {
                    acc[item.company_id] = [];
                }
                acc[item.company_id].push(item);
                return acc;
            }, {});

            return groupedByCompany;
        }
        else {
            return null;
        }

    } catch (error) {
        console.error(error);
        return null;
    }

}

const GetStatusStepInsuranceDocument = async (companyid) => {
    try {
        const isValidStepOne = await checkCompanyAddress(companyid);
        const isValidStepTwo = await checkUserMetadataDoc(companyid);
        const isValidStepThree = await IsAllDocumentsApproved(companyid);
        if (isValidStepTwo && isValidStepOne && isValidStepThree) {
            return true;
        }
        else {
            return false;
        }

    } catch (error) {
        console.error(error);
        return false;
    }

}

const CheckInsuranceCondition = async () => {
    const { data: docs1, error1 } = await supabase
        .from('insurance_docs')
        .select('key')
        .eq('company_id', localStorage.getItem('companyID'))
        .eq('status', 'approved');

    const { data: docs2, error2 } = await supabase
        .from('attestation_docs')
        .select('id')
        .eq('company_id', localStorage.getItem('companyID'))
        .eq('verification_status', 'Approved');

    if (!error1 && !error2) {
        return docs1.length == 3 && docs2.length == 3;
    } else {
        return false;
    }
};

const CheckAccessInsuranceRoute = async () => {
    const { data: docs, error } = await supabase
        .from('insurance_docs')
        .select('key')
        .eq('company_id', localStorage.getItem('companyID'))
    //.or('status.eq.refused,status.eq.approved,status.eq.expired');

    //console.log("CheckAccessInsuranceRoute:" + docs.length)

    if (!error) {
        return docs.length > 0; // Devuelve true si hay al menos un documento que cumpla con las condiciones
    } else {
        return false;
    }
};

/**
 * 
 * @returns 
 */
const CheckInsuranceConditionAllAprobated = async () => { //TODO
    const { data: docs, error } = await supabase
        .from('insurance_docs')
        .select('*')
        .eq('company_id', localStorage.getItem('companyID'));

    //console.log("docs:" + docs.length)

    if (!error) {
        return docs.length == 3;
    }
    else {
        return false;
    }
};

/**
 * Devuelve si este user tiene acceso a los viajes
 * @param {*} company_id 
 * @returns 
 */
const CheckAccessToRides = async (company_id) => {
    try {
        const response = await axios.get(
            apiUrl + '/tables/check/accessToRides?company_id=' + company_id,
            {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            }
        );
        //console.log(response)
        return response.data;
    } catch (error) {
        console.error(error);
        return false;
    }

};

/**
 * 
 * @param {*} user 
 * @param {*} tableName 
 * @param {*} columnNameCompare 
 * @param {*} valueCompare 
 * @returns 
 */
const GetTableCount = async (user, tableName, columnNameCompare, valueCompare) => {
    try {
        if (user) {
            const encryptedData = encrypt(user.id);
            //console.log(user.id)
            //onsole.log(encryptedData)
            const { data: responseToken } = await axios.get(
                apiUrl + "/admin/auth",
                {
                    params: {
                        token: encryptedData,
                    },
                    headers: {
                        accept: "application/json",
                        "Content-Type": "application/json",
                    },
                }
            );
            //console.log("responseToken" + responseToken.tokenJWT);

            if (responseToken) {
                let eqUrl;
                if (columnNameCompare != null) {
                    eqUrl = columnNameCompare != null ? '?eq.' + columnNameCompare + "=" + valueCompare : ""
                }
                const response = await axios.get(
                    apiUrl + '/tables/' + tableName + '/count' + eqUrl,
                    {
                        headers: {
                            'accept': 'application/json',
                            'Content-Type': 'application/json',
                            "Authorization": "Bearer " + responseToken.tokenJWT
                        }
                    }
                );
                //console.log(response.data)
                return response.data;
            }
        }
    } catch (error) {
        console.error(error);
        return 0;
    }
}

// Exportar todas las funciones individualmente
export {
    IsCompletedSetup,
    checkCompanyAddress,
    UserMetadata,
    isEmailConfirmed,
    isPhoneConfirmed,
    isFileExist,
    isAdmin,
    isAdminDoc,
    GetAdmin, checkUserMetadataDoc,
    InsertDocumentsDoc,
    IsAllDocumentsApproved,
    GetAllDocumentsByCompany,
    CheckInsuranceCondition,
    CheckAccessToRides,
    GetTableCount,
    CheckAccessInsuranceRoute
};

// También puedes exportarlas todas juntas como un objeto
export default {
    IsCompletedSetup,
    checkCompanyAddress,
    UserMetadata,
    isEmailConfirmed,
    isPhoneConfirmed,
    isFileExist,
    isAdmin,
    isAdminDoc,
    GetAdmin, checkUserMetadataDoc,
    InsertDocumentsDoc,
    IsAllDocumentsApproved,
    GetAllDocumentsByCompany,
    CheckInsuranceCondition,
    CheckAccessToRides,
    GetTableCount,
    CheckAccessInsuranceRoute
};


import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Error404 from "../views/Error.js";
import metadataFunctions from '../SupabaseChecking.js';


const ProtectedRouteAdmin = ({ element }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [IsAdmin, setIsAdmin] = useState(false);
  const [IsAdminDoc, setIsAdminDoc] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthorization = async () => {
      const isAdmin = await metadataFunctions.isAdmin()/*localStorage.getItem("isAdmin") === "true"*/;
      const isAdminDoc = await metadataFunctions.isAdminDoc();
      setIsAdmin(isAdmin);
      setIsAdminDoc(isAdminDoc);
      setIsLoading(false);
    };

    checkAuthorization();
  }, []);

  if (!isLoading) {
    if (!IsAdmin) {
      return <Error404 />;
    }
    else if (IsAdminDoc) {
      navigate('/admindoc/dashboard');

    }
    else {
      return element;
    }
  }
};

export default ProtectedRouteAdmin;

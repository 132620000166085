import CryptoJS from 'crypto-js';

const SECRET_KEY = 'b1b6e0d6c1c7f42073d8f6d95efa828ce2d0b95c3efb066fe1ec385900adeee3';

// Convertir la clave hexadecimal a un formato que pueda usar CryptoJS
const getKeyFromHex = (hex) => {
    let bytes = [];
    for (let i = 0; i < hex.length; i += 2) {
        bytes.push(parseInt(hex.substr(i, 2), 16));
    }
    return CryptoJS.lib.WordArray.create(bytes);
};

// La función de cifrado
export const encrypt = (userId) => {
    const date = new Date().toISOString();
    const key = getKeyFromHex(SECRET_KEY);
    const iv = CryptoJS.lib.WordArray.create(); // IV de 16 bytes de ceros
    const encrypted = CryptoJS.AES.encrypt(`${userId}:${date}`, key, { iv: iv });
    return encrypted.toString();
};

// documentDriverTypes.js
const documentDriverTypes = {
    'Wheelchair Driver': [
        "Not Select yet",
        "Driver's License",
        "Background Check",
        "Background Check (County)",
        "Drug Screen",
        "Motor Vehicle Record",
        "Centene Requirement (Attestation Form)", //No expire
        "E-Verify", //No expire
        "English Language (Attestation Form)", //No expire
        "Medical Examination",
        "Wheelchair Training",
        "Defensive Driving",
        "CPR + First Aid Certification",
        "CTAA Wheelchair"
    ],
    'Ambulatory Driver': [
        "Not Select yet",
        "Driver's License",
        "Background Check",
        "Background Check (County)",
        "Drug Screen",
        "Motor Vehicle Record",
        "Centene Requirement (Attestation Form)", //No expire
        "E-Verify", //No expire
        "English Language (Attestation Form)", //No expire
        "Medical Examination",
        "CTAA ONLINE",
        "Defensive Driving",
        "CPR + First Aid Certification",
        "CTAA Wheelchair"
    ],
    'Stretcher Driver': [
        "Not Select yet",
        "Driver's License",
        "Background Check",
        "Background Check (County)",
        "Motor Vehicle Record",
        "Centene Requirement (Attestation Form)", //No expire
        "E-Verify", //No expire
        "English Language (Attestation Form)", //No expire
        "Medical Examination",
        "Wheelchair Training",
        "Defensive Driving",
        "CPR + First Aid Certification",
        "CTAA Wheelchair"
    ],
};

export default documentDriverTypes;

// roles.js
const AdminRoles = Object.freeze({
    FULL_ADMIN: 'full_admin',
    DOC_ADMIN: 'doc_admin',
    ORGANIZATION_ADMIN: 'organization_admin',
    ORGANIZATION_USER: 'organization_user',
    RIDER_SUPERVISOR: 'supv_ride',
    USER: 'authenticated'
  });
  
  export default AdminRoles;
  
const documentVehicleTypes = {
    'Ambulatory Vehicle': [ //OK
        "Not Select yet",
        "Vehicle Registration",
        "Vehicle Insurance Card",
        "Modivcare's Vehicle Inspection",
        "CTAA Wheelchair"
    ],
    'Stretcher Vehicle': [ // OK
        "Not Select yet",
        "Vehicle Registration",
        "Vehicle Insurance Card",
        "Modivcare's Vehicle Inspection",
        "CTAA Wheelchair"
    ],
    'Wheelchair Vehicle': [ // OK
        "Not Select yet",
        "Vehicle Registration",
        "Vehicle Insurance Card",
        "Modivcare's Vehicle Inspection",
        "CTAA Wheelchair"
    ]
};

export default documentVehicleTypes;

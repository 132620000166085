import { useRoutes, useNavigate, useLocation } from "react-router-dom";
import Themeroutes from "./routes/Router";
import { supabase } from "./SupabaseClient";
import { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/fonts/fonts.css';
import { ThemeProvider } from './ThemeContext'; // Importar ThemeProvider
import ThemeToggle from './ThemeToggle'; // Ruta al componente ThemeToggle

const App = () => {
  const [session, setSession] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const run = async () => {
      const { data } = await supabase.auth.getSession();
      setSession(data.session);
    };
    run();

    const { data: authListener } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      if (_event === 'SIGNED_OUT') {
        console.log("SIGNED_OUT");
        // Clear local and session storage
        [window.localStorage, window.sessionStorage].forEach((storage) => {
          Object.entries(storage).forEach(([key]) => {
            storage.removeItem(key);
          });
        });
        navigate('/login');
      } else if (_event === "PASSWORD_RECOVERY") {
        console.log("PASSWORD_RECOVERY");
        const newPassword = prompt("What would you like your new password to be?");
        const updateUser = async (newPassword) => {
          const { data, error } = await supabase.auth.updateUser({ password: newPassword });
          if (error) {
            alert("There was an error updating your password.");
          } else if (data) {
            alert("Password updated successfully!");
            navigate('/login');
          }
        };
        updateUser(newPassword);
      } else if (_event === "USER_UPDATED") {
        console.log("USER_UPDATED");
      }
    });

    return () => {
      authListener?.subscription.unsubscribe();
    };
  }, [navigate]);

  const routing = useRoutes(Themeroutes);

  return (
    <ThemeProvider>
      <div className="App">
        {location.pathname === '/login' && (
          <div style={{ position: 'fixed', top: '20px', left: '20px', zIndex: '1000' }}>
            <ThemeToggle />
          </div>
        )}
        {location.pathname === '/invitation' && (
          <div style={{ position: 'fixed', top: '20px', left: '20px', zIndex: '1000' }}>
            <ThemeToggle />
          </div>
        )}
        {location.pathname === '/forgetpassword' && (
          <div style={{ position: 'fixed', top: '20px', left: '20px', zIndex: '1000' }}>
            <ThemeToggle />
          </div>
        )}
        <div className="dark">{routing}</div>
      </div>
    </ThemeProvider>
  );
};

export default App;

import React, { useEffect, useContext } from "react";
import { Row, Col, CardTitle, Button, CardSubtitle, Card } from "reactstrap";
import ComponentCard from "../components/ComponentCard";
import errorBg from "../assets/images/error-bg-CzpptKmz.jpg";
import "../layouts/ErrorCL1CzngD.css";
import "../views/ui/LightTheme-mEegPd2d.css";
import { ThemeContext } from "../ThemeContext.js"; // Importar el contexto

const About = () => {
  const { theme } = useContext(ThemeContext); // Usar el contexto

  useEffect(() => {
    console.log("storedTheme:", theme);
  }, [theme]);

  return (
    <div>
      <div id="root">
        <div className="ltr " dir="ltr">
          <div
            className="loginBox"
            style={{
              //   background: `rgb(255, 255, 255) url("${errorBg}") center bottom no-repeat`,
              backgroundColor: theme === "dark" ? "#000000" : "#ffffff", // Cambia el color de fondo
              backgroundImage: `url("${errorBg}")`, // Añade la imagen de fondo
              backgroundPosition: "center bottom",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover", // Ajusta esto según cómo quieras que se ajuste la imagen
              height: "100vh", // Ajusta esto según sea necesario
            }}
          >
            <div className="d-flex align-items-center justify-content-center h-100">
              <div className="text-center">
                <h1 className="error-title">404</h1>
                <div className="my-3">
                  <h4>PAGE NOT FOUND !</h4>
                  <span className="text-muted d-block fs-5">
                    You seem to be trying to find his way home{" "}
                  </span>
                </div>
                <a className="btn btn-danger" href="/">
                  Back to home
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <script
        dangerouslySetInnerHTML={{
          __html: " const global = globalThis; ",
        }}
      />
      <svg
        height="0"
        id="SvgjsSvg3979"
        style={{
          left: "-100%",
          opacity: "0",
          overflow: "hidden",
          position: "absolute",
          top: "-100%",
        }}
        version="1.1"
        width="2"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <defs id="SvgjsDefs3980" />
        <polyline id="SvgjsPolyline3981" points="0,0" />
        <path
          d="M1184.6969658713708 156.06000020599822C1184.6969658713708 156.06000020599822 1191.7667687367584 156.06000020599822 1191.7667687367584 156.06000020599822C1191.7667687367584 156.06000020599822 1191.7667687367584 156.06000020599822 1191.7667687367584 156.06000020599822C1191.7667687367584 156.06000020599822 1191.7667687367584 156.06000020599822 1191.7667687367584 156.06000020599822C1191.7667687367584 156.06000020599822 1198.836571602146 156.06000020599822 1198.836571602146 156.06000020599822C1198.836571602146 156.06000020599822 1198.836571602146 159.00780020988896 1198.836571602146 159.00780020988896C1198.836571602146 159.00780020988896 1191.7667687367584 159.00780020988896 1191.7667687367584 159.00780020988896C1191.7667687367584 159.00780020988896 1191.7667687367584 161.6377002133595 1191.7667687367584 161.6377002133595C1191.7667687367584 161.6377002133595 1191.7667687367584 159.00780020988896 1191.7667687367584 159.00780020988896C1191.7667687367584 159.00780020988896 1184.6969658713708 159.00780020988896 1184.6969658713708 159.00780020988896C1184.6969658713708 159.00780020988896 1184.6969658713708 155.56000020599822 1184.6969658713708 155.56000020599822 "
          id="SvgjsPath3982"
        />
      </svg>
    </div>
  );
};

export default About;

import React, { createContext, useState, useEffect } from 'react';
import { supabase } from './SupabaseClient'; // Asegúrate de que la ruta a tu cliente Supabase sea correcta
import metadataFunctions from './SupabaseChecking';
import AdminRoles from './enum/roles';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
    const [driversCount, setDriversCount] = useState(0);
    const [vehiclesCount, setVehiclesCount] = useState(0);
    const [IsShowOtherSection, setShowOtherSection] = useState(false);
    const [IsShowInsurance, setShowInsurance] = useState(false);
    const [IsShowRides, setShowRides] = useState(false);
    const [ridesCount, setRidesCount] = useState(0);
    const [authorizationsCount, setAuthorizationsCountCount] = useState(0);

    const fetchUpdates = async () => {
        try {
            const { data } = await supabase.auth.getSession();
            if (data) {
                if (data.session.user.role == AdminRoles.ORGANIZATION_ADMIN || data.session.user.role == AdminRoles.ORGANIZATION_USER) {
                    if (localStorage.getItem('companyID') != undefined) {
                        const drivers = await metadataFunctions.GetTableCount(data.session.user, 'drivers_company', 'company_id', localStorage.getItem('companyID'));
                        const vehicles = await metadataFunctions.GetTableCount(data.session.user, 'vehicles_company', 'company_id', localStorage.getItem('companyID'));
                        const insuranceCondition = await metadataFunctions.CheckInsuranceCondition();
                        const accessToInsurance = await metadataFunctions.CheckAccessInsuranceRoute();
                        const ridesCondition = await metadataFunctions.CheckAccessToRides(localStorage.getItem('companyID'));
                        
                        const authorizationsV = await metadataFunctions.GetTableCount(data.session.user, 'vehicles_company', 'status', "Authorized");
                        const authorizationsD = await metadataFunctions.GetTableCount(data.session.user, 'drivers_company', 'status', "Authorized");
                        //if (driversError) console.error(driversError);
                        //if (vehiclesError) console.error(vehiclesError);

                        //console.log(drivers)
                        //console.log(vehicles)
                        setDriversCount(drivers);
                        setVehiclesCount(vehicles);
                        setShowOtherSection(insuranceCondition);
                        setShowInsurance(accessToInsurance);
                        setShowRides(ridesCondition);
                        setAuthorizationsCountCount(authorizationsV + authorizationsD)
                    }
                }
                else if (data.session.user.role == AdminRoles.RIDER_SUPERVISOR) {
                    const { data: trips, error: errorRides } = await supabase.from('trips').select('*');
                    if (errorRides) console.error(errorRides);
                    setRidesCount(trips ? trips.length : 0)
                }
            }
        }
        catch (error) {

        }
    };

    useEffect(() => {
        fetchUpdates();
    }, []);

    return (
        <AppContext.Provider value={{ driversCount, vehiclesCount, ridesCount, authorizationsCount, IsShowOtherSection, IsShowInsurance, IsShowRides, fetchUpdates }}>
            {children}
        </AppContext.Provider>
    );
};

import React, { useContext, useState, useEffect } from 'react';
import { Navigate, useLocation  } from "react-router-dom";
import Error404 from "../views/Error.js";
import metadataFunctions from '../SupabaseChecking.js';
import AdminRoles from '../enum/roles.js';
import { AppContext } from '../AppContext.js';
import { CircularProgress, Box, Typography } from "@mui/material";
import { Row, Col } from "react-bootstrap";

const ProtectedRoute = ({ element, conditions }) => {
  const [admin, setAdmin] = useState('');

  const [isAllowed, setIsAllowed] = useState(null);
  const {
    fetchUpdates,
  } = useContext(AppContext);

  useEffect(() => {
    const checkConditions = async () => {
      const results = await Promise.all(conditions.map(condition => condition()));
      const allConditionsMet = results.every(result => result);
      setIsAllowed(allConditionsMet);
    };
    checkConditions();
  }, [conditions]);

  if (isAllowed === null) {
    return element; // O cualquier indicador de carga
  }
  else if (isAllowed) {
    if (admin === AdminRoles.FULL_ADMIN) {
      return <Navigate to="/admin" />;
    } else if (admin === AdminRoles.DOC_ADMIN) {
      return <Navigate to="/admindoc" />;
    } else if (admin === AdminRoles.RIDER_SUPERVISOR) {
      return <Navigate to="/supervisor_rider" />;
    } else {
      const isAuthenticated = !!localStorage.getItem("authToken");
      return isAuthenticated ? element : <Navigate to="/login" />;
    }
  }
  else {
    fetchUpdates();//Actualizar Sidebar
    return <Navigate to="/setup" />;
  }

  //console.log("insurance:" + insuranceCondition)
  //console.log("isLoading:" + isLoading)
  //if (isLoading) {
  //return element/*<CircularProgress />*/;
  /*} else if (admin === AdminRoles.FULL_ADMIN) {
    return <Navigate to="/admin" />;
  } else if (admin === AdminRoles.DOC_ADMIN) {
    return <Navigate to="/admindoc" />;
  } else if (admin === AdminRoles.RIDER_SUPERVISOR) {
    return <Navigate to="/supervisor_rider" />;
  } else {
    const restrictedPaths = [
      '/insurance', '/organization', '/performance',
      '/authorizations', '/issues', '/about',
      '/drivers', '/vehicles'
    ];

    if (restrictedPaths.some(path => location.pathname.includes(path)) && !insuranceCondition/*&& !isLoading && routeChanges >= 2*///) {
  //return <Error404 />;
  //}

  //if (location.pathname === '/rides' && !insuranceCondition && !ridesCondition/* && !isLoading && routeChanges >= 2*/) {
  // return <Error404 />;
  //}

  //const isAuthenticated = !!localStorage.getItem("authToken");
  //return isAuthenticated ? element : <Navigate to="/login" />;
  //}
};

export default ProtectedRoute;

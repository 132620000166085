import React, { useEffect, useContext } from "react";
import { DropdownToggle, DropdownMenu, DropdownItem, Button, Dropdown } from "reactstrap";
//import { VscColorMode } from "react-icons/vsc";
import { MdLightMode, MdNightlight } from "react-icons/md";
import { FaSun } from "react-icons/fa";
import { ThemeContext } from "../src/ThemeContext"; // Importar el contexto

const ThemeToggle = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const { theme, changeTheme } = useContext(ThemeContext); // Usar el contexto

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  // const Handletoggle = () => {
  //   setIsOpen(!isOpen);
  // };

  useEffect(() => {
    document.documentElement.setAttribute("data-bs-theme", theme);
  }, [theme]);

  const handleThemeToggle = (newTheme) => {
    changeTheme(newTheme);
  };

  return (
    <>
      <div className="hstack gap-2">
        {/* <Button color="primary" size="sm" className="d-sm-block d-md-none" onClick={Handletoggle}>
          {isOpen ? <i className="bi bi-x"></i> : <i className="bi bi-three-dots-vertical"></i>}
        </Button> */}
      </div>
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle color="primary">
          <MdLightMode style={{ fontSize: "18px", marginTop: '-4px', color: theme === 'dark' ? '#ffffff' : '#000000' }} className="rounded-circle" width="30" />
        </DropdownToggle>
        <DropdownMenu style={{ "minWidth": "25px"}}>
          <DropdownItem onClick={() => handleThemeToggle("light")}>
            <FaSun style={{ marginTop: "-3px" }} /> Light
          </DropdownItem>
          <DropdownItem onClick={() => handleThemeToggle("dark")}>
            <MdNightlight style={{ marginTop: "-3px" }} /> Dark
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default ThemeToggle;

import React from "react";
import "./loader.scss";
import { Spinner } from "reactstrap";

const Loader = () => (
  <div className="fallback-spinner">
      <div className="loading">
        <Spinner color="primary" />
        {/* <video width="150" height="150" autoPlay muted loop>
        <source src={LoagingVanComponent} type="video/webm" />
        <Spinner color="primary" />
      </video> */}
      </div>
  </div>
);
export default Loader;
